/*
 * @Author: your name
 * @Date: 2020-07-03 17:33:49
 * @LastEditTime: 2020-07-29 14:33:02
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \pcweb\src\service\home.js
 */ 
import { get } from "../common/query";
export const GETHOME = data => get("/home/info",data);
export const GET_IRISGEMINFO = () => get("home/ylsinfo");
export const GET_CERTIFICATE = data => get("/order/getTZS", data);