<!--
 * @Author: your name
 * @Date: 2020-07-20 09:11:36
 * @LastEditTime: 2020-12-14 10:56:22
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \pcweb\src\views\home\Home.vue
-->
<template>
  <div class="home">
      <!---->
          <!--轮播banner-->
          <swiper class="banner" :options="mySwiperOption" ref="mySwiper" v-if="bannerList.length" :loop="true">
            <swiper-slide class="swiper-slide" v-for="(item, index) in bannerList" :key="index">
              <img :src="item.pic.picUrl" @click="bais(item,index)" v-if="item.pic.type == 1" />
            </swiper-slide>
            <!-- 分页器 -->
            <div class="swiper-pagination" slot="pagination"></div>
            <!-- 左右箭头 -->
            <!-- <div class="swiper-button-prev swiper-button-white le" slot="button-prev"></div>
            <div class="swiper-button-next swiper-button-white rig" slot="button-next"></div>-->
          </swiper>
 
          <div class="warp">
            <!--理念--> 
            <div class="about">
                  <div>
                  <p class="a_title">ABOUT US</p>
                  <p class="a_desc">关于我们</p>
                  <p class="a_text"><span>伊莉丝®</span>是国内首家新生儿纪念钻石品牌。<br/>我们通过高科技手段，从宝宝胎发或脐带中提取碳元素，在实验室内模拟形成钻石所需要的高温高压环境，培育成存储生命最初印记的钻石，让其陪伴孩子一生，世代传承。</p>
                  </div>
            </div>


           <!--单品--> 
          <div class="single">
                <div class="s_title">
                  <p>甄选单品</p>
                  <div class="s_red"></div>
                  <div class="s_button" @click="$router.push('/production.html')">更多甄选单品</div>
                </div>
                <div class="s_le">
                    <div class="s_img" v-for="item in single" :key="item.id" @click="tzsing(item.id)">
                          <div class="heis"></div>
                          <img :src="item.img" alt="">
                    </div>
                </div>
          </div>

           <!--关于定制--> 
          <div class="made">
                <div class="m_title">
                  <p>关于定制</p>
                  <div class="m_red"></div>
                </div>
              <!--专属定制--> 
               <div class="exclusive">
                  <div>
                  <p class="e_title">专属定制</p>
                  <p class="e_desc">
                  每一颗伊莉丝钻石中都含有您提供的宝宝胎发<br>
                  中的生物碳成分，<br>
                  伊莉丝钻石的培育就像母亲孕育生命的过程一样，<br>
                  从无到有，从小到大，从原石到成品，<br>
                  细工雕琢，精心镶嵌，让每个人都充满期待！
                  </p>
                  </div>
               </div>
              <!--灵感来源--> 
              <div class="afflatus">
                  <div>
                    <p class="aff_title">灵感来源</p>
                    <p class="aff_desc">
                      伊莉丝的所有设计灵感均源于您对宝宝一生的<br>
                      祝福和期盼。从伊莉丝钻石成分溯源到饰品元素设计<br>
                      无一不是每对宝爸宝妈亲力打造的结果。
                    </p>
                  </div>
              </div>
          </div>
          
            <!--制作流程--> 
          <div class="flow">
                <div class="f_title">
                  <p>制作流程</p>
                  <div class="f_red"></div>
                </div>
                <div class="vied">
                <img src="../../assets/images/home/liucheng.png" alt="" class="backgr">
                      <div class="v_img">
                          <img src="@/assets/images/home/dazi.png" alt="">
                          <p class="img_t">专业与精湛工艺精心制品</p>
                          <p class="img_bot"></p>
                      </div>
                      <div class="v_video">
                        <video src="https://img.irisgem.com.cn/gwylsdzlcsp.mp4"  id="video" @click="plays()"></video>

                          <div class="back" v-if="show">
                            <div class="hesi">
                            <div class="img">
                                <img src="@/assets/images/home/play.png" alt="" @click="plays()" class="img_imgs">
                                <p>点击播放</p>
                            </div>
                            </div>
                          </div>


                      </div>
                      <img src="@/assets/images/home/shu.png" alt="" class="v_shu">
                </div>
          </div>
         

         <!--定制流程--> 
          <div class="zation">
                <div class="ion_title">
                  <p>定制流程</p>
                  <div class="ion_red"></div>
                </div>

                <div class="step">
                    <div class="hei"></div>
                    <div class="step_img">
                         <div :class="item.st?'step_i mar':'step_i marb'" v-for="(item,i) in steps" :key="item.id" @click="stepa(item,item.id)">
                                <img :src="item.img" alt="">
                                <img :src="step[i].img" alt="">
                          </div>
                    </div>
                    <div class="bai">
                        <div class="b_t">
                          <p>{{steps[index].a}}</p>
                          <p>{{steps[index].b}}</p>
                          <p>{{steps[index].c}}</p>
                        </div>
                    </div>
                    <div class="zhezhao"></div>
                </div>
          </div>


          <!--客户故事--> 
          <div class="story" @click="$router.push('/family.html')">
            <div>
              <p class="story_title">客户故事</p>
              <p class="story_desc">
                用宝宝一缕胎发，“种”下一颗有温度、有故事的伊莉丝钻石 <br>
                这是宝宝独一无二、不可复制的初生纪念礼物 <br>
                也是父母以“身体发肤”的名义<br>
                许下终生守护的承诺
              </p>
            </div>
            <div class="zhegai"></div>
          </div>

         <!--商品-->
         <div class="pcod">
            <div class="cod_xili">
              <div class="x_left" @click="$router.push('/productList.html?seriesid=5')">
                <img src="https://img.irisgem.com.cn//pc/gseries.jpg" alt="">
              </div>
              <div class="x_right">
                <p class="x_title">设计师<br>专属打造</p>
                <div class="x_hei"></div>
                <p class="x_desc">the most special<br>gift for<br>every mommy</p>
                <p class="x_left_title">GEM轻奢系列</p>
                <!-- <p class="x_left_desc">IRISGEM</p> -->
              </div>
            </div>
            <div class="cod_pin">
                <div class="p_left">
                    <div class="texts">
                          <div class="dw">
                          <p class="texts_title">全新设计<br>亟待辣妈召唤</p>
                          <div class="gd" @click="$router.push('/serieslist')">浏览更多系列</div>
                          </div>
                    </div>
                    <img src="@/assets/images/home/xianglian.png" alt="" @click="danpin(1585297747399)">
                </div>
                <div class="p_right">
                  <img src="@/assets/images/home/erding.png" alt="" @click="danpin(1585297747406)">
                </div>
            </div>
         
         
         </div> 






     </div>
  </div>
</template>

<script>
import { GETHOME } from "../../service/home";//引入home请求地址
export default {
  name:'Home',
  data(){
    return{
      times: new Date().getTime(),
      radums: parseInt(Math.random() * 100 + 10),
      num: "",
      //轮播banner参数
      mySwiperOption: {
      //显示分页
      // pagination: {
      //   el: ".swiper-pagination"
      // },
      //设置点击箭头
      // navigation: {
      //   nextEl: ".swiper-button-next",
      //   prevEl: ".swiper-button-prev"
      // },
      //自动轮播
      autoplay: {
        delay: 2000,
        //当用户滑动图片后继续自动轮播
        disableOnInteraction: false
      },
      //开启循环模式
      loop: true,
    },
    bannerList: [],//banner图
    single:[//单品
      {img:require('../../assets/images/home/dan1.png'),id:1585297747406},
      {img:require('../../assets/images/home/dan2.png'),id:1585297747405},
      {img:require('../../assets/images/home/dan3.png'),id:1585297747402},
    ],
     step: [
        //步骤1
        {img: require("../../assets/images/home/jiaofu.jpg"),id: 0},
        {img: require("../../assets/images/home/tanyuan.jpg"),id: 1,},
        {img: require("../../assets/images/home/peiyu.jpg"),id: 2,},
        {img: require("../../assets/images/home/yuanshi.jpg"),id: 3,},
        {img: require("../../assets/images/home/renzheng.jpg"),id: 4,},
        {img: require("../../assets/images/home/chengpin.jpg"),id: 5,},
      ],
    steps: [
    //步骤2
        {img: require("../../assets/images/home/step1.png"),id: 0,a: "交付样本",b: "#采集样本",c: "#元素分析",st:false},
        {img: require("../../assets/images/home/step2.png"),id: 1,a: "碳源转换",b: "#碳源萃取", c: "#制备碳基",st:false},
        {img: require("../../assets/images/home/step3.png"),id: 2,a: "钻石培育", b: "#技术培育",c: "#获取原石",st:false},
        {img: require("../../assets/images/home/step4.png"),id: 3,a: "原石处理",b: "#切割打磨",c: "#嵌套设计",st:false},
        {img: require("../../assets/images/home/step5.png"),id: 4,a: "检验认证",b: "#出具碳源证书",c: "#鉴定机构认证",st:false},
        {img: require("../../assets/images/home/step6.png"),id: 5,a: "成品交付",b: "#支付尾款", c: "#成品配送",st:false},
    ],
    show:true,//控制视频封面是否显示
    index:0,//步骤流程下标
    num:0,//每次点击下标
  }
},
  methods:{
    //请求数据
    gets(){
        this.num = this.times + this.radums;
        if (this.$getCookie("num") == null) {
          this.$setCookie("num", this.num);
        }

        GETHOME({ dname: this.$getCookie("num") }).then(res => {
          this.bannerList = res.attachment.ads
        });
    },
    //跳转单品
    tzsing(id){
      window.open(`/productInfo.html?id=${id}`)
    },
    //页脚跳转单品
    danpin(i){
        window.open(`/productInfo.html?id=${i}`)
      
    },
    //流程动画
    stepa(t,i){
      this.index = i
      this.steps.map(res=>{
        res.st = false
      })
       t.st = !t.st
    },
    //播放视频
    plays(){
      var video = document.getElementById("video");
      if (video.paused) {
        video.play();
        this.show = false
        video.controls = true
      } else {
        video.pause();
        this.show = true
      }
    },
    //banner跳转
    bais(item, index) {

      // console.log(index,item)
      // if (item.type == 10) {
      //   this.$router.push({
      //     path: "/theme",
      //     query: {
      //       id: item.item
      //     }
      //   });
      // } else if (item.type == 2) {
      //   this.$router.push({
      //     path: "/productInfo.html",
      //     query: {  
      //       id: item.item
      //     }
      //   });
      // } else if (item.type == 9) {
      //   this.$router.push({ path: "/designer" });
      // }
      if(index == 1){
        this.$router.push('/productList.html?seriesid=2')
      }else if(index == 2){
        this.$router.push('/designer.html')
      }
    },
    startInt(){
      clearInterval(window.ttime)
        var a = 0
        window.ttime = setInterval(function(){
          if($('.step_i')[a]){
            $('.step_i')[a].click()
            a++
          }else{
            a = 0
          }
          
        },4000)
    }
  },
  mounted() {
    this.startInt()
    this.gets()//加载页面请求数据
  },
}
</script>

<style lang="scss" scoped>
.le {
  margin-left: 40px;
}
.rig {
  margin-right: 40px;
}
.banner {
  width: 1596px;
  height: 700px;
  overflow: hidden;
  .swiper-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .swiper-slide video {
    width: 1596px;
    object-fit: cover;
  }
}
.warp{
  width: 1596px;
  margin: 100px auto;
}
.about{
  width: 1409px;
  height: 1173px;
  background: url('https://img.irisgem.com.cn/pc/index/muying.png');
  background-size: cover;
  margin: 0 auto;
  position: relative;
  div{
    position: absolute;
    top: 47%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  .a_title{
    font-size:100px;
    font-family:'微软雅黑';
    font-weight:500;
    color:rgba(57,5,8,1);
  }
  .a_desc{
    font-size:64px;
    font-family:PingFang SC;
    font-weight:500;
    color:rgba(146,29,34,1);
    line-height:48px;
  }
  .a_text{
    width:365px;
    font-size:18px;
    font-family:PingFang SC;
    font-weight:400;
    color:rgba(0,0,0,1);
    line-height:36px;
    position: absolute;
    right: -100px;
    top: 260px;
    text-align: justify;
    span{
      font-weight: bold
    }
  }
}
.single{
  width: 100%;
  margin:100px 0;
  .s_title{
    text-align: center;
    p{
      font-size:42px;
      font-family:'宋体';
      font-weight:bold;
      color:rgba(55,7,9,1);
      line-height:48px;
    }
    .s_red{
      width:100px;
      height:4px;
      background:rgba(205,43,44,1);
      margin: 0 auto ;
      margin-top: 40px;
    }
    .s_button{
      width:164px;
      height:50px;
      border:1px solid rgba(146,29,34,1);
      border-radius:6px;
      background: #fff;
      font-size:16px;
      font-family:PingFang SC;
      font-weight:400;
      color:rgba(55,7,9,1);
      line-height:50px;
      margin: 0 auto;
      margin-top: 30px;
      margin-bottom: 60px;
      &:hover{
        cursor: pointer;
        background: #000;
        color: #fff;
        transition: all 1s;
      }
    }
  }
  .s_le{
    width: 100%;
    display: flex;
    justify-content: space-around;
    .s_img{
      width: 390px;
      height: 390px;
      position: relative;
       &:hover .heis{
        cursor: pointer;
         opacity: 0.5;
         transition: all 1s;
      }
      .heis{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
        opacity: 0;
      }
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}



.made{
  width: 100%;
  margin:100px 0;
  .m_title{
    text-align: center;
    p{
      font-size:42px;
      font-family:'宋体';
      font-weight:bold;
      color:rgba(55,7,9,1);
      line-height:48px;
    }
    .m_red{
      width:100px;
      height:4px;
      background:rgba(205,43,44,1);
      margin: 0 auto ;
      margin-top: 40px;
    }
  }
  .exclusive,.afflatus{
    width: 100%;
    height:446px;
  }
  .exclusive{
    background: url('../../assets/images/home/ing1.png');
    background-size: cover;
    margin-top: 60px;
    position: relative;
    div{
      position: absolute;
      right: 13%;
      top: 24%;
      text-align: center;
    }
    .e_title{
      font-size:36px;
      font-family: '宋体';
      font-weight:bold;
      color:rgba(51,51,51,1);
      line-height:64px;
    }
    .e_desc{
      font-size:16px;
      font-family: '微软雅黑';
      font-weight:400;
      color:rgba(51,51,51,1);
      line-height:26px;
    }
  }
  .afflatus{
    background: url('../../assets/images/home/ing2.png');
    background-size: cover;
    margin: 40px 0;
     position: relative;
    div{
      position: absolute;
      left: 13%;
      top: 30%;
      text-align: center;
    }
    .aff_title{
      font-size:36px;
      font-family: '宋体';
      font-weight:bold;
      color:rgba(51,51,51,1);
      line-height:64px;
    }
    .aff_desc{
      font-size:16px;
      font-family: '微软雅黑';
      font-weight:400;
      color:rgba(51,51,51,1);
      line-height:26px;
    }
  }
}


.flow{
  width: 100%;
  margin:100px 0;
  .f_title{
    text-align: center;
    p{
      font-size:42px;
      font-family:'宋体';
      font-weight:bold;
      color:rgba(55,7,9,1);
      line-height:48px;
    }
    .f_red{
      width:100px;
      height:4px;
      background:rgba(205,43,44,1);
      margin: 0 auto ;
      margin-top: 40px;
    }
  }
  .vied{
    width: 100%;
    height:857px;
    background:rgba(245,245,245,1);
    margin-top: 60px;
    position: relative;
    .backgr{
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 79%;
    }
    .v_video{
      width: 892px;
      height: 590px;
      position: absolute;
      top: 13%;
      left: 31%;
      video{
        width: 100%;
        height: 100%;
      }
      .back{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: url('https://img.irisgem.com.cn/phone/video/f/f1.jpg');
        background-size: cover;
      }
      .hesi{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
      .img{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        text-align: center;
        .img_imgs{
          width: 50px;
          height: 50px;
        }
        p{
          text-align: center;
          line-height: 50px;
          color: #fff;
          font-size: 24px;
        }}
      }
    }
    .v_shu{
      position: absolute;
      right:4%;
      top: 24%;
      height: 408px;
      width: 18px;
    }
  }
  .v_img{
    position: absolute;
    left: 14%;
    top: 28%;
    z-index: 1;
    img{
      width: 573px;
      height: 181px;
    }
    .img_t{
      font-size:16px;
      font-family:PingFang SC;
      font-weight:500;
      color:rgba(255,255,255,1);
      line-height:120px;
    }
    .img_bot{
      font-size:16px;
      font-family:PingFang SC;
      font-weight:500;
      color:rgba(255,255,255,1);
      line-height:120px;
      position: absolute;
      top: 460px;
    }
  }
}


.zation{
  width: 100%;
  margin:100px 0;
  .ion_title{
    text-align: center;
    p{
      font-size:42px;
      font-family:'宋体';
      font-weight:bold;
      color:rgba(55,7,9,1);
      line-height:48px;
    }
    .ion_red{
      width:100px;
      height:4px;
      background:rgba(205,43,44,1);
      margin: 0 auto ;
      margin-top: 40px;
    }
  }
  .step{
    width: 100%;
    margin: 60px 0;
    position: relative;
    .hei{
      width: 100%;
      height: 118px;
      background: #000;
    }
    .bai{
      width: 324px;
      height: 280px;
      border: 4px solid #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      z-index: 1;
      .b_t{
        width:100% ;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        text-align: center;
        p:nth-child(1){
          font-size:36px;
          font-family:'宋体';
          font-weight:bold;
          color:rgba(255,255,255,1);
          line-height:48px;
        }
         p:nth-child(2){
           padding-top: 15px;
         }
        p:nth-child(2), p:nth-child(3){
          font-size:24px;
          font-family:'微软雅黑';
          font-weight:400;
          color:rgba(255,255,255,1);
          line-height:48px;
        }
      }
    }
    .zhezhao{
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.5);
      position: absolute;
      top: 0;
      left: 0;
    }
    .mar{
      img:nth-child(1){
         transition: all 2s;
         transform: translateY(-100%);
      }
      img:nth-child(2){
         transition: all 2s;
         transform: translateY(-100%);
      }
    }
    .marb{
      img:nth-child(1){
         transition: all 2s;
         transform: translateY(0);
      }
      img:nth-child(2){
         transition: all 2s;
         transform: translateY(100%);
      }
    }
    .step_img{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      background: #000;
      .step_i{
        height: 302px;
        overflow: hidden;
        img{
          width: 100%;
          height: 303px;
          object-fit: cover;
        }
      }
      .step_i:nth-child(1),.step_i:nth-child(3){
        width: 30%;
      }
      .step_i:nth-child(2){
        width: 40%;
      }
      .step_i:nth-child(4),.step_i:nth-child(6){
        width: 35%;
      }
      .step_i:nth-child(5){
        width: 30%;
      }
    }
  }

}
.story{
  width: 100%;
  height: 446px;
  margin: 150px 0;
  background: url('../../assets/images/home/story.png');
  background-size:cover;
  position: relative;
  &:hover .zhegai{
    display: block;
    cursor: pointer;
  }
  div{
    position: absolute;
    top: 34%;
    left: 14%;
    text-align: center;
  }
  .story_title{
    font-size:36px;
    font-family:'宋体';
    font-weight:bold;
    color:rgba(255,255,255,1);
    line-height:64px;
  }
  .story_desc{
    font-size:16px;
    font-family:'微软雅黑';
    font-weight:500;
    color:rgba(255,255,255,1);
    line-height:28px;
    text-align: left
  }
  .zhegai{
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    position: absolute;
    top: 0;
    left: 0;
    display: none;
  }
}

.pcod{
  width: 100%;
  .cod_xili{
    height:772px;
    display: flex;
    justify-content: space-around;
    .x_left{
      width: 75%;
      height: 100%;
      &:hover{
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          cursor: pointer;
      }
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .x_right{
      width: 25%;
      height: 100%;
      text-align: right;
      position: relative;
      .x_title{
        width: 100%;
        font-size:48px;
        font-family:'宋体';
        font-weight:400;
        color:rgba(55,7,9,1);
        line-height:60px;
        padding-right: 40px;
        float: right;
      }
      .x_hei{
        width:99px;
        height:4px;
        background:#cd2b2c;
        float: right;
        margin-right: 40px;
        margin-top: 10px;
        margin-bottom: 30px;
      }
      .x_desc{
        width: 100%;
        font-size:16px;
        font-family:'微软雅黑';
        font-weight:400;
        color:#370709;
        line-height:28px;
        padding-right: 40px;
        float: right;
      }
      .x_left_title{
        font-size:60px;
        font-family:'宋体';
        font-weight:900;
        color:rgba(55,7,9,1);
        line-height:44px;
        position: absolute;
        left: -54%;
        bottom: 34%;
      }
      .x_left_desc{
        font-size:60px;
        font-family:PingFang SC;
        font-weight:300;
        color:rgba(146,29,34,1);
        line-height:44px;
        position: absolute;
        left: 0%;
        bottom: 24%;
      }
    }
  }
}

.cod_pin{
  width: 100%;
  height: 380px;
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
  
  .p_left{
    width: 75%;
    display: flex;
    img{
      width: 65%;
      height: 100%;
      object-fit: cover;
      &:hover{
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          cursor: pointer;
      }
    }
    .texts{
      width: 35%;
      text-align: center;
      height: 100%;
      position: relative;
      .dw{
        position: absolute;
        top: 50%;
        left:50%;
        transform: translate(-50%,-50%);
      }
      .texts_title{
        width: 350px;
        font-size:32px;
        font-family:PingFang SC;
        font-weight:400;
        color:rgba(55,7,9,1);
        line-height:44px;
      }
      .gd{
        width:164px;
      height:50px;
      border:1px solid rgba(146,29,34,1);
      border-radius:6px;
      background: #fff;
      font-size:16px;
      font-family:PingFang SC;
      font-weight:400;
      color:rgba(55,7,9,1);
      line-height:50px;
      margin: 0 auto;
      margin-top: 30px;
      margin-bottom: 60px;
      &:hover{
        cursor: pointer;
        background: #000;
        color: #fff;
        transition: all 1s;
      }
      }
    }
  }
  .p_right{
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      &:hover{
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          cursor: pointer;
      }
    }
  }
}

@keyframes op {
  form{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}

@keyframes mar {
  form{
    transform: translateY(0);
  }
  to{
     transform: translateY(-100%);
  }
}
</style>